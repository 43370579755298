import React, { Suspense, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import NotAllowed from "views/auth/NotAllowed";
import Loader from "components/loader";
import CacheBuster from "react-cache-buster";
import packageFile from "../package.json";
import { io } from "socket.io-client";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getStock } from "shared/slice/dashboard/DashboardService";
import { setStockQuantity } from "shared/slice/dashboard/DashboardSlice";
import { setStockValorisation } from "shared/slice/dashboard/DashboardSlice";
import { store } from "shared";
import { getTagLogs } from "shared/slice/tag/TagService";
import { setTagLogs } from "shared/slice/tag/TagSlice";

// Lazy load layout components
const RtlLayout = React.lazy(() => import("layouts/rtl"));
const AdminLayout = React.lazy(() => import("layouts/admin"));
const AuthLayout = React.lazy(() => import("layouts/auth"));
const SuperAdminLayout = React.lazy(() => import("layouts/superAdmin"));

const App = () => {
  const { version } = packageFile;
  const isProduction = process.env.NODE_ENV === "production";
  const auth = useSelector((state) => state.authentification.isLoggedIn);
  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );

  const [readerDisconnected, setReaderDisconnected] = useState(false);
 
  const socket = useRef(undefined);
  useEffect(() => {
    const connectListener = () => {
      console.log("socket connected");
      socket.current.emit("join_room", loggedInUser?.IDClient.toString());
    };
    const receiverNotifications = (data) => {
      console.log("Notification received:", data);
      const fetchStock = async () => {
        try {
          const response = await getStock(loggedInUser.IDClient);
          store.dispatch(setStockQuantity({ stockQuantity: response.totalQuantity }));          
          store.dispatch(setStockValorisation({ stockValorisation: response.totalValorization }));
        } catch (error) {
          console.error("Error fetching customers to deliver:", error);
        }
      };
      const fetchTagLogs = async () => {
        try {
          const response = await getTagLogs(loggedInUser.IDClient);
          store.dispatch(setTagLogs({ tagLogs: response }));          
        } catch (error) {
          console.error("Error fetching customers to deliver:", error);
        }
      };
      fetchStock()
      fetchTagLogs()
      if (!data.isAuthorized) {
        toast.warn(
          `${data.updatedArticle.Designation} was out from ${data.newTagMouvement.Reader.emplacement} without authorization. Reason: ${data.authorizationMessage}`, 
          {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      
    };
    const readerconnect = (data) => {
      console.log("reader-connect", data);
      setReaderDisconnected(false);
      toast.success("Reader connected! Please check the connection.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };
    const readerdisconnect = (data) => {
      console.log("reader-disconnect", data);
      setReaderDisconnected(true);
      toast.error("Reader disconnected! Please check the connection.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };
    const disconnectListener = () => {
      console.log("socket disconnected");
    };
    if (loggedInUser) {
      // Initialize socket connection
      socket.current=io("https://rbscan.realitybird.com:3000");
      // Attach event listeners
      socket.current.on("connect", connectListener);
      socket.current.on("disconnect", disconnectListener);
      socket.current.on("reader-connect", readerconnect);
      socket.current.on("reader-disconnect", readerdisconnect);
      socket.current.on("send_notification", receiverNotifications);
    } else {
      console.log(socket);
      
      if (socket.current) {
        socket.current.off("connect", connectListener);
        socket.current.off("disconnect", disconnectListener);
        socket.current.off("send_notification", receiverNotifications);
        socket.current.disconnect();
      }
    }
  }, [loggedInUser]);

  return (
    <I18nextProvider i18n={i18n}>
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
        metaFileDirectory={"."}
      >
        <Suspense fallback={<Loader isLoading={true} />}>
          <Routes>
            {/* Authenticated user routes */}
            {auth && loggedInUser && (
              <>
                {/* AdminLayout for users without 'rb' */}
                {loggedInUser.rb !== true && (
                  <Route path="admin/*" element={<AdminLayout />} />
                )}
                {/* SuperAdminLayout for users with 'rb' */}
                {loggedInUser.rb === true && (
                  <Route path="superAdmin/*" element={<SuperAdminLayout />} />
                )}
              </>
            )}

            {/* Unauthenticated user routes */}
            {!auth && <Route path="auth/*" element={<AuthLayout />} />}

            {/* Not allowed route based on App_Type */}
            {auth &&
              loggedInUser?.App_Type !== "Web" &&
              loggedInUser?.App_Type !== "All" && (
                <Route path="*" element={<NotAllowed />} />
              )}

            {/* Fallback route */}
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    auth
                      ? loggedInUser.rb
                        ? "/superAdmin"
                        : "/admin"
                      : "/auth/sign-in"
                  }
                  replace
                />
              }
            />
          </Routes>
        </Suspense>
      </CacheBuster>
    </I18nextProvider>
  );
};

export default App;
