import { createSlice } from "@reduxjs/toolkit";

export const InvoiceInitialState = {
  invoices: [],
  bonLivraisons:[],
  invoiceLog:[],
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: InvoiceInitialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload.invoices;
    },
    setBonLivraisons: (state, action) => {
      state.bonLivraisons = action.payload.bonLivraisons;
    },
    setInvoiceLog :(state,action)=>{
      state.invoiceLog =action.payload.invoiceLog
    }
  },
});

export const { setInvoices,setBonLivraisons,setInvoiceLog } = invoiceSlice.actions;
